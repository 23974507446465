<template lang="pug">
div
  .page-header
    .row
      .col-md-6
        h1 Clientes
      .col-md-5.col-md-offset-1
          .text-right
            router-link(to='/sistema/clientes/novo').btn.btn-success Novo Cliente
    .row
      .col-md-3
          label Clientes:
          input.form-control(v-model="filtro.cliente", placeholder="Clientes")
      .col-md-2
          label Telefone:
          input.form-control(v-model="filtro.telefone", placeholder="Telefone")
      .col-md-3
          label Bairro:
          input.form-control(v-model="filtro.bairro", placeholder="Bairro")
      .col-md-3
        select-form(v-model='filtro.usuario', :value='filtro.usuario', name='Usuário', url='/usuarios', id='name', titulo='name')
      .col-md-1
        br
        button.btn.btn-primary(@click.prevent='getLimparFiltro') Limpar
  br
  kendo-datasource(ref="datasource1",
    :transport-read="{ url: `${$url}/clientes`, beforeSend: onBeforeSend }",
    :page-size='100',
    :server-paging="true",
    :schema-data="'data'",
    :schema-total="'total'",
    :filter="filter",
    :server-filtering="true",
    :schema-parse="parse")
  kendo-grid.grid-clientes(:data-source-ref="'datasource1'",
    :pageable='true',
    :height="500",
    :pageable-always-visible="false",
    :pageable-page-sizes="[5, 10, 20, 100]",
    ref="grid")
    kendo-grid-column(:field="'nome'", :title="'Cliente'", :width='350', :locked='true',
                :lockable='false', :template="itemTemplate")
    kendo-grid-column(:field="'cpf_cnpj'", :title="'CPF/CNPJ'", :width='120')
    kendo-grid-column(:field="'telefone1'", :title="'Telefone 1'", :width='180')
    kendo-grid-column(:field="'name'", :title="'Usuário'", :width='300', :lockable="false")
    kendo-grid-column(:field="'telefone2'", :title="'Telefone 2'", :width='180')
    kendo-grid-column(:field="'telefone3'", :title="'Telefone 3'", :width='180')
    kendo-grid-column(:field="'end_bairro'", :title="'Bairro'", :width='200')
    kendo-grid-column(:field="'datacad_atu'", :title="'Atualizado em'", :width='200')
    kendo-grid-column(:field="'nome'", :template="ACTIONTEMPLATE", :title="'Ações'", :width="145", :locked="true")
</template>

<script>
import Vue from 'vue'
import '@progress/kendo-ui'
import '@progress/kendo-theme-default/dist/all.css'
import '@progress/kendo-ui/js/cultures/kendo.culture.pt-BR'
import '@progress/kendo-ui/js/messages/kendo.messages.pt-BR'
import moment from 'moment'
import { Grid, GridInstaller } from '@progress/kendo-grid-vue-wrapper'
import {
  DataSource,
  DataSourceInstaller
} from '@progress/kendo-datasource-vue-wrapper'
import SelectForm from '@/components/form/SelectForm'
import Template from '@/mixins/Template'
import Permission from '@/mixins/Permission'
moment.locale('pt-BR')

Vue.use(GridInstaller)
Vue.use(DataSourceInstaller)
// eslint-disable-next-line no-undef
kendo.culture('pt-BR')

export default {
  components: {
    Grid,
    DataSource,
    SelectForm
  },
  mixins: [Template, Permission],
  watch: {
    'filtro.cliente' () {
      localStorage.setItem('_filtroClientes', JSON.stringify(this.filtro))
    },
    'filtro.telefone' () {
      localStorage.setItem('_filtroClientes', JSON.stringify(this.filtro))
    },
    'filtro.bairro' () {
      localStorage.setItem('_filtroClientes', JSON.stringify(this.filtro))
    },
    'filtro.usuario' () {
      localStorage.setItem('_filtroClientes', JSON.stringify(this.filtro))
    }
  },
  computed: {
    filter () {
      return {
        logic: 'and',
        filters: [
          {
            field: 'nome',
            operator: 'contains',
            value: this.filtro.cliente
          },
          {
            field: 'telefone',
            operator: 'contains',
            value: this.filtro.telefone
          },
          {
            field: 'bairro',
            operator: 'contains',
            value: this.filtro.bairro
          },
          {
            field: 'usuario',
            operator: 'contains',
            value: this.filtro.usuario
          }
        ]
      }
    }
  },
  data () {
    return {
      clientes: [],
      filtro: {
        cliente: null,
        telefone: null,
        bairro: null,
        usuario: null
      }
    }
  },
  methods: {
    parse (d) {
      d.data.map(i => {
        i.nome = i.nome ? i.nome.toUpperCase() : ''
        i.cpf_cnpj = i.cpf_cnpj ? i.cpf_cnpj.replace(/[^\d]+/g, '') : ''
        i.end_cep = i.end_cep ? i.end_cep.replace(/[^\d]+/g, '') : ''
        i.telefone1 = i.telefone1 ? i.telefone1.replace(/[^\d]+/g, '') : ''
        i.telefone2 = i.telefone2 ? i.telefone2.replace(/[^\d]+/g, '') : ''
        i.telefone3 = i.telefone3 ? i.telefone3.replace(/[^\d]+/g, '') : ''
        i.end_bairro = i.end_bairro ? i.end_bairro.toUpperCase() : ''
        i.name = i.name ? i.name.toUpperCase() : ''
        i.datacad_atu = moment(i.datacad_atu).format('L LT')
        return i
      })
      return d
    },
    onBeforeSend (xhr) {
      xhr.setRequestHeader(
        'Authorization',
        'Bearer ' + localStorage.getItem('token')
      )
    },
    handleClientes () {
      this.$axios('/clientes').then(resp => {
        this.clientes = resp.data
      })
    },
    itemTemplate (e) {
      var vm = this
      return {
        template: Vue.component('temp', {
          template: `
            <a :href='url(templateArgs.id)' style='color: #337ab7;' @click.prevent="buttonClick(templateArgs.id)">
              {{ templateArgs.nome }}
            </a>
           `,
          data () {
            return {
              templateArgs: {}
            }
          },
          methods: {
            url (id) {
              return `/sistema/clientes/${id}`
            },
            buttonClick (id) {
              vm.$router.push(`/sistema/clientes/${id}`)
            }
          }
        }),
        templateArgs: e
      }
    },
    getLimparFiltro () {
      let filtro = {
        cliente: null,
        telefone: null,
        bairro: null,
        usuario: null
      }
      localStorage.setItem('_filtroClientes', JSON.stringify(filtro))
      this.filtro = filtro
    }
  },
  mounted () {
    this.$refs.datasource1.kendoDataSource.read()
    let filtro = localStorage.getItem('_filtroClientes')
      ? JSON.parse(localStorage.getItem('_filtroClientes'))
      : null
    if (filtro) {
      this.filtro = filtro
    }
  }
}
</script>

<style lang="stylus" scoped>
.k-grid a {
  color: #337ab7;
}

tbody tr {
  height: 57px !important;
}
</style>

<style lang="stylus">
.grid-clientes.k-grid.k-widget.k-display-block.k-grid-lockedcolumns > div.k-grid-content.k-auto-scrollable > table > tbody > tr {
  height 49px
}
</style>